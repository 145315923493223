import React from 'react'
import 'twin.macro'

import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import CategoryNav from '../components/Post/CategoryNav'
import PostContentGroup from '../components/Post/ContentGroup'

import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../helpers'

const CategoryTemplate = ({ data, location }) => {
  const { categorySlug, categoryName } = data.currentCategory
  const posts = data.currentCategory.posts.nodes

  return (
    <Layout location={location}>
      <Seo
        title={`${categoryName} ${JSON.parse(
          data.wp.seo.contentTypes.post.schema.raw
        )['@graph'][0]?.name.replace('&#039;', "'")}`}
        postSchema={formatYoastSeo(data.wp.seo.contentTypes.post.schema).raw}
      />
      <Container tw="grid mt-10 md:(grid-cols-3) lg:(grid-cols-4) xl:(grid-cols-5) gap-8">
        {/* Sticky Categories Nav */}
        <div tw="h-full relative">
          <CategoryNav currentCategory={categorySlug} />
        </div>

        {/* POSTS SECTION */}
        <div tw="md:(col-span-2) lg:(col-span-3) xl:(col-span-4)">
          <PostContentGroup posts={posts} />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wp {
      ...WpSeoSchema
    }
    # Get posts from the category
    currentCategory: wpCategory(id: { eq: $id }) {
      categoryId: id
      categoryName: name
      categorySlug: slug
      posts {
        nodes {
          date
          excerpt
          title
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 990) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`

export default CategoryTemplate
